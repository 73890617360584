<template>
  <div>
    <Row :gutter="8" v-if="!statementId">
      <i-col span="24" class="p-t-10">
        <span class="title" style="width: 100px">结算客户 </span>
        <i-select
          v-model="chooseAdvertiserId"
          size="small"
          style="width: 300px"
          clearable
        >
          <i-option
            v-for="advertiser in advertisers"
            :key="advertiser.advertiserId"
            :value="advertiser.advertiserId"
            >{{ advertiser.advertiserName }}</i-option
          >
        </i-select>
      </i-col>
      <i-col span="24" class="p-t-10">
        <span class="title" style="width: 100px">发布类型 </span>
        <i-select
          v-model="contractCategory"
          size="small"
          style="width: 300px"
          clearable
        >
          <i-option
            v-for="type in contractTypes"
            :key="type.id"
            :value="type.id"
            >{{ type.name }}</i-option
          >
        </i-select>

        <Checkbox class="p-l-10" v-model="promotion">促销</Checkbox>
      </i-col>
    </Row>
    <Row class="p-t-10 m-b-5" v-if="!statementId">
      <i-col span="24">
        <span>设置当前结算单对应的订单方案：</span>
        <a @click="handleOpenOrderPicker">选择订单</a>
        <span v-show="validOrder" class="text-red"
          >请选择当前结算单对应的订单方案！</span
        >
      </i-col>
    </Row>
    <Row v-if="statementId">
      <i-col span="24">
        <Card :bordered="false" dis-hover>
          <p slot="title">订单概况</p>
          <div slot="extra">
            <span v-if="dateCheck" style="color: red"
              >支付日期已经过期，请联系管理员</span
            >
            <i-button
              size="small"
              type="success"
              :disabled="dateCheck"
              @click="handleSubmit"
              >提交审批</i-button
            >
            <i-button size="small" class="btn-black m-l-8" @click="submit(0)"
              >保存并关闭</i-button
            >
          </div>
          <Row>
            <i-col span="12">
              <strong>客户：</strong>
              {{ statementDetail.advertiserName }}
            </i-col>
            <i-col span="12">
              <strong>发布品牌：</strong>
              {{ statementDetail.brandName }}
            </i-col>
          </Row>
          <Row>
            <i-col span="12">
              <strong>发布档期：</strong>
              {{ statementDetail.schedule }}
            </i-col>
            <i-col span="12">
              <strong>订单备注：</strong>
              {{ order.remark }}
            </i-col>
          </Row>
        </Card>
        <Card :bordered="false" dis-hover>
          <p slot="title">设置支付方式</p>
          <Row :gutter="10">
            <i-col span="12">
              <i-select v-model="currentDiscountId" size="small">
                <i-option
                  v-for="(dis, i) in discounts"
                  :key="i"
                  :value="dis.paydiscountId"
                  >{{ dis.desc }}</i-option
                >
              </i-select>
            </i-col>
            <i-col span="12">
              <div>
                结算单发布费总金额：
                <s class="title">{{ formatMoney(usePrice) }}</s>
                <span class="money">{{ formatMoney(computedUsePrice) }}</span>
              </div>
            </i-col>
          </Row>
          <payment :items="payItems" @checkDate="changeDateCheck"></payment>
        </Card>
        <Card :bordered="false" dis-hover>
          <p slot="title">发布费汇总</p>
          <div v-show="normal.length > 0">
            <div v-for="category in normal" :key="category.categoryId">
              <Tag color="blue">{{ category.category }}</Tag>
              <Timeline>
                <TimelineItem
                  v-for="(schedule, i) in category.schedules"
                  :key="i"
                >
                  <h4>{{ computeSchedule(schedule) }}</h4>
                  <products
                    :products="schedule"
                    :versionId="versionId"
                    @reloadProducts="getStatementForOrderInfo"
                  ></products>
                </TimelineItem>
              </Timeline>
            </div>
          </div>
        </Card>
        <Card :bordered="false" dis-hover>
          <p slot="title">结算单附件</p>
          <fileUpload
            ref="fileuploadComponent"
            type="STATEMENT_ATTACHMENT_FILE"
            :existsFile="existsFile"
            :actionUrl="'/ooh-scp/v1/contractfile/uploadfile'"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
            :relateId="statementId"
            :format="fileTypes"
            @on-removeFile="handleRemoveFile"
          ></fileUpload>
        </Card>
      </i-col>
    </Row>

    <!-- 弹窗选中当前确认单所包含的订单 -->
    <Modal v-model="showOrdersPicker" :width="800" footer-hide>
      <div slot="header">
        <p>当前可以选择的订单</p>
      </div>
      <orders-picker
        v-if="showOrdersPicker"
        :params="agreementParms"
        :existedOrders="[]"
        @on-chosen="handleOrdersChosen"
      ></orders-picker>
    </Modal>
  </div>
</template>

<script>
// components
import ordersPicker from '@/components/contract/contractDetail/common/OrdersPicker'
import payment from '@/components/statement/Payments'
// import estimate from '@/components/statement/Estimate'
import products from '@/components/statement/ProductsEdit'
import fileUpload from '@/components/common/FileUploadV2'

// utils
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule, ParseDate } from '@/utils/dateFormat'
import { buildCategories } from '@/utils/orderUtils'

// api

import { deleteFiles, getFileList } from '@/api/scp/contractfile'
import { getOrderAdvertiser, getOrder } from '@/api/order/order'
import {
  createStatementDraft,
  updateStatement,
  getStatementDetail
} from '@/api/scp/statement'

import { getOrderSettlementPrice } from '@/api/scp/statementorder'
import { getPayDiscountList } from '@/api/scp/paydiscount'
import { getcategorylist } from '@/api/scp/scpbusiness'

import { judgeUseStoredContract } from '@/api/scp/storedContract'

export default {
  components: {
    ordersPicker,
    payment,
    // estimate,
    products,
    fileUpload
  },
  props: {
    baseId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      userId: this.$store.getters.token.userInfo.userId,
      advertisers: [],
      chooseAdvertiserId: 0,
      contractCategory: 0,
      statementDetail: {},

      showOrdersPicker: false,
      validOrder: false,

      statementId: 0,
      orderId: 0,
      discounts: [],
      currentDiscountId: 0,
      order: {},
      EstimateService: [],
      normal: [],
      special: [],
      dateCheck: false,
      products: [],
      fileTypes: ['img', 'jpg', 'png', 'doc', 'docx', 'pdf'],
      existsFile: [],

      contractTypes: [],
      promotion: false,

      versionId: 0
    }
  },
  created () {
    if (this.baseId) {
      this.statementId = this.baseId
      this.getExistsFiles()
    } else {
      this.initAdviertiserPageData()
      getcategorylist({}).then((res) => {
        this.contractTypes = res
      })
    }
  },
  methods: {
    initAdviertiserPageData () {
      getOrderAdvertiser({ userId: this.userId, status: 0 }).then((res) => {
        this.advertisers = res
      })
    },

    handleOpenOrderPicker () {
      if (
        this.chooseAdvertiserId === 0 ||
        this.chooseAdvertiserId === undefined ||
        this.contractCategory === 0 ||
        this.contractCategory === undefined
      ) {
        this.$Notice.warning({
          title: '请完善信息',
          desc: '请选择当前结算单所涉及到的客户并确认结算单发布类型！ '
        })
      } else {
        this.agreementParms = {
          userId: this.userId,
          advertiserResultBean: { id: this.chooseAdvertiserId }
        }

        this.showOrdersPicker = true
      }
    },
    handleOrdersChosen (orderId) {
      this.showOrdersPicker = false
      debugger
      this.$Modal.confirm({
        title: '操作确认？',
        content: '<p>确定要为订单创建结算单？</p>',
        loading: true,
        onOk: () => {
          const createBean = {
            orderIds: JSON.stringify([orderId]),
            contractCategory: this.contractCategory,
            salePolicy: this.promotion ? 2 : 1
          }

          createStatementDraft(createBean)
            .then((res) => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '创建结算单成功' })
                this.statementId = res.statementId
              }
              this.$Modal.remove()
            })
            .catch(() => {
              this.$Modal.remove()
            })
        },
        onCancel: () => {
          this.showOrdersPicker = true
        }
      })
    },

    formatMoney (money) {
      return toMoney(money)
    },
    changeDateCheck (data) {
      this.dateCheck = data
    },
    initPageData () {
      getStatementDetail({ statementId: this.statementId }).then((res) => {
        this.orderId = res.orderIdList[0]
        this.versionId = res.versionId
        res.schedule = GetCurrentSchedule(res.startDate, res.endDate)
        this.statementDetail = res
        this.currentDiscountId = res.payDiscountId
        this.getStatementForOrderInfo()
      })
    },
    getStatementForOrderInfo () {
      getOrderSettlementPrice({
        orderId: this.orderId,
        versionId: this.versionId
      }).then((products) => {
        this.products = products
        // 常规资源采购类型： 平面媒体、电子屏、主题媒体等等
        this.normal = buildCategories(products)
        // 特惠资源采购类型
        // this.special = buildCategories(
        //   products.filter((x) => x.priceType === 0)
        // )

        // 获取预估服务费
        // getOrderPredictServicePrice({ orderId: this.orderId }).then(estimate => {
        //   this.EstimateService = estimate
        // })

        this.getDiscounts()
      })

      getOrder({ orderId: this.orderId }).then((order) => {
        this.order = order
        this.order.schedule = GetCurrentSchedule(
          order.startDate,
          order.endDate
        )
      })
    },
    getDiscounts () {
      const publisher = this.$store.getters.token.userInfo.publisherId
      var discounts = []
      judgeUseStoredContract({ statementId: this.statementId }).then((res) => {
        if (res) {
          // const item = { desc: '储值抵扣', paydiscountId: -99, discount: 0, paydayproportions: [{ day: -1, proportion: 1 }] }
          // discounts.push(item)
          getPayDiscountList({ publisherId: publisher, type: 3 }).then(
            (data) => {
              data.forEach((x) => {
                let desc = '储值抵扣,媒介使用费全抵扣，服务费'
                x.paydayproportions
                  .filter((x) => x.proportion > 0)
                  .forEach((y, index) => {
                    const header = y.day < 0 ? '刊前' : '刊后'
                    desc +=
                      header +
                      Math.abs(y.day) +
                      '天付款' +
                      (y.proportion * 100).toFixed(2) +
                      '% '
                  })
                x.desc = desc
              })

              discounts = discounts.concat(data)
              getPayDiscountList({ publisherId: publisher, type: 1 }).then((result) => {
                result.forEach((x) => {
                  let desc = ''
                  x.paydayproportions
                    .filter((x) => x.proportion > 0)
                    .forEach((y, index) => {
                      const header = index === 0 ? '刊前' : '刊后'
                      desc +=
                  header +
                  Math.abs(y.day) +
                  '天付款' +
                  (y.proportion * 100).toFixed(2) +
                  '% ,'
                    })
                  desc += '媒介使用费打' + (x.discount * 10).toFixed(2) + '折'
                  x.desc = desc
                })

                // this.discounts = this.discounts.concat(result)
                discounts = discounts.concat(result)
                this.discounts = discounts
                if (this.discounts.length && this.currentDiscountId === -1) {
                  this.currentDiscountId = data[0].paydiscountId
                }
              })
            }
          )
        } else {
          getPayDiscountList({ publisherId: publisher, type: 1 }).then((data) => {
            data.forEach((x) => {
              let desc = ''
              x.paydayproportions
                .filter((x) => x.proportion > 0)
                .forEach((y, index) => {
                  const header = index === 0 ? '刊前' : '刊后'
                  desc +=
                  header +
                  Math.abs(y.day) +
                  '天付款' +
                  (y.proportion * 100).toFixed(2) +
                  '% ,'
                })
              desc += '媒介使用费打' + (x.discount * 10).toFixed(2) + '折'
              x.desc = desc
            })
            discounts = discounts.concat(data)
            this.discounts = discounts
            // this.discounts = this.discounts.concat(data)

            if (this.discounts.length && this.currentDiscountId === -1) {
              this.currentDiscountId = this.discounts[0].paydiscountId
            }
          })
        }
      })
    },
    handleSubmit () {
      this.$Modal.confirm({
        title: '是否确认要提交该结算单？',
        content:
          '您确认提交该结算单后，将无法更改其中信息，该结算单即将进入审批通道',
        onOk: () => {
          this.submit(4)
        }
      })
    },
    submit (status) {
      const stateMent = {
        orderIds: JSON.stringify([this.orderId]),
        statementId: this.statementId,
        status: status,
        payments: JSON.stringify(
          this.payItems.map((x) => {
            return {
              date: ParseDate(x.date),
              amount: x.money
            }
          })
        )
      }
      if (this.chkDiscount.type === 1) {
        stateMent.paymentMethod = 1
        stateMent.payDiscountId = this.chkDiscount.paydiscountId
      } else if (this.chkDiscount.type === 3) {
        stateMent.paymentMethod = 2
      }
      if (this.statementId !== 0) {
        updateStatement(stateMent).then((res) => {
          if (res && !res.errcode) {
            this.$emit('on-submit')
          }
        })
      }
    },
    computeSchedule (products) {
      if (products.length > 0) {
        const product = products[0]
        return GetCurrentSchedule(product.startDate, product.endDate)
      }
    },
    getExistsFiles () {
      const that = this
      const data = {
        type: 'STATEMENT_ATTACHMENT_FILE',
        relateId: this.statementId
      }
      getFileList(data).then((res) => {
        that.existsFile = res.map((file) => {
          file.name = file.fileName
          return file
        })
      })
    },
    handleRemoveFile (file) {
      const id = JSON.stringify([file.id || file.response.id])
      deleteFiles({ fileIds: id }).then((res) => {
        this.$refs.fileuploadComponent.removeSuccess(file)
      })
    }
  },
  computed: {
    isEdit () {
      return this.baseId !== -1
    },
    payItems: function () {
      let payItems = []
      this.chkDiscount.paydayproportions.filter((x) => x.proportion > 0).forEach((item, index) => {
        payItems.push({
          date: Date.parse(item.day > 0 ? this.order.endDate : this.order.startDate) + item.day * 1000 * 60 * 60 * 24,
          money:
            (
              this.usePrice *
              this.chkDiscount.discount *
              item.proportion
            ).toFixed(2) *
              1 +
            (index ? 0 : this.serviceMount)
        })
      })

      // payItems[0].date =
      //   Date.parse(this.order.startDate) +
      //   this.chkDiscount.paydayproportions[0].day * 1000 * 60 * 60 * 24
      payItems = payItems.filter((x) => x.money > 0)

      const length = payItems.length
      if (length > 0) {
        let payed = 0
        payItems.forEach((item) => {
          payed += item.money
        })
        payItems[length - 1].money +=
          (this.computedUsePrice + this.serviceMount - payed).toFixed(2) * 1
      }

      return payItems
    },
    chkDiscount: function () {
      if (this.discounts.length) {
        return this.discounts.find(
          (x) => x.paydiscountId === this.currentDiscountId
        )
      } else {
        return { discount: 1, paydayproportions: [{ day: 0, proportion: 1 }] }
      }
    },
    computedUsePrice: function () {
      return this.usePrice * this.chkDiscount.discount
    },
    serviceMount: function () {
      let amount = 0
      this.products.forEach((product) => {
        amount += product.signServicePrice
      })
      return amount
    },
    usePrice: function () {
      let total = 0
      this.products.forEach((product) => {
        total += product.normalSettlementPrice // + product.totalInstallSettlePrice + product.totalProduceSettlePrice
      })
      return total
    }
  },
  watch: {
    statementId (val) {
      if (val) {
        this.initPageData()
      } else {
        this.initAdviertiserPageData()
      }
    },
    baseId (val) {
      this.statementId = val
    }
  }
}
</script>
